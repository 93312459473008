import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'sonner';
import { PAGE_ROUTES } from '@/constants';
import { useProfileStateSync } from '@/hooks/useProfileStateSync.ts';
import { RoutesConfig } from '@/types/common.ts';
import { lazy, Suspense } from 'react';
import { LayoutContainer } from '@/components/common/AuthLayout';
import { ProtectedRouteWrapper } from '@/components/common/ProtectedRouteWrapper';
import LoginPage from '@/pages/LoginPage';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import LandingPage from '@/pages/LandingPage';
import JobPostingPage from '@/pages/JobPostingPage';
import MobileHeroAnimation from '@/components/common/MobileHeroAnimation';
import { useAuthStore } from '@/store/authStore.ts';
import { useMediaQuery, useTheme } from '@mui/material';

const RegistrationPage = lazy(() => import('@/pages/RegistrationPage'));
const EmailVerificationPage = lazy(
  () => import('@/pages/EmailVerificationPage')
);
const OnboardingPage = lazy(() => import('@/pages/OnboardingPage'));

const routesConfig: RoutesConfig = [
  {
    path: `/${PAGE_ROUTES.Registration}`,
    element: <RegistrationPage />,
    isProtected: true,
    accessCheck: (isAuth) => !isAuth,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.EmailVerification}`,
    element: <EmailVerificationPage />,
  },
  {
    path: `/${PAGE_ROUTES.ForgotPassword}`,
    element: <ForgotPasswordPage />,
  },
  {
    path: `/${PAGE_ROUTES.ResetPassword}`,
    element: <ResetPasswordPage />,
  },
  {
    path: `/${PAGE_ROUTES.Landing}`,
    element: <LandingPage />,
  },
  {
    path: `/${PAGE_ROUTES.Onboarding}`,
    element: <OnboardingPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !isProfileCompleted,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.Login}`,
    element: <LoginPage />,
    isProtected: true,
    accessCheck: (isAuth) => !isAuth,
    redirectTo: `/`,
  },
  {
    path: `/${PAGE_ROUTES.JobPosting}`,
    element: <JobPostingPage />,
    isProtected: true,
    accessCheck: (isAuth, isEmailVerified, isProfileCompleted) =>
      isAuth && isEmailVerified && !!isProfileCompleted,
    redirectTo: `/`,
  },
];

function App() {
  useProfileStateSync();
  const isAuth = useAuthStore((state) => state.isAuth);
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Suspense fallback={<LayoutContainer />}>
        <Routes>
          {routesConfig.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRouteWrapper
                  path={route.path}
                  isProtected={route?.isProtected}
                  redirectTo={route?.redirectTo}
                  accessCheck={route?.accessCheck}
                >
                  {route.element}
                </ProtectedRouteWrapper>
              }
            />
          ))}
        </Routes>
        <Toaster richColors />
      </Suspense>
      {!isAuth && downMd && <MobileHeroAnimation />}
    </>
  );
}

export default App;
