import { Box, styled, Typography } from '@mui/material';
import { useJobPostingStepsStore } from '@/pages/JobPostingPage/store/jobPostingStepStore.ts';

const StepContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  marginTop: '10px',
});

const StepItem = styled(Box)<{ active: number }>(({ theme, active }) => ({
  flex: 1,
  borderRadius: '20px',
  height: '10px',
  backgroundColor: !!active ? '#fff' : theme.palette.purple1,
  maxWidth: '310px',
}));

const JobPostingStepper = () => {
  const currentStep = useJobPostingStepsStore((state) => state.currentStep);
  const stepsAmount = 2;
  return (
    <>
      <StepContainer>
        {[...Array(stepsAmount)].map((_, index) => (
          <StepItem key={index} active={Number(index + 1 === currentStep)} />
        ))}
      </StepContainer>
      <Typography
        sx={{ display: 'block', marginTop: '5px' }}
        variant="poppins16Regular"
      >
        {currentStep} of {stepsAmount}
      </Typography>
    </>
  );
};

export default JobPostingStepper;
