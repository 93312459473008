import { toast } from 'sonner';

export const handleMutationError = (error: Error) => {
  try {
    const errorMessage = JSON.parse(error?.message)?.message;
    toast.error(errorMessage);
  } catch (e) {
    toast.error('An unknown error occurred.'); // to prevent parsing error
  }
};
