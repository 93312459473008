import {
  Box,
  FormControl,
  InputProps,
  styled,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { StyledInput } from '@/styled';
import { ReactNode, useState } from 'react';
import EyeIcon from '@/assets/icons/eye.svg?react';
import { StyledFormLabel } from '@/components/forms/FormLabel';

type FormInputProps<TSchema extends FieldValues> = InputProps & {
  name: Path<TSchema>;
  control: Control<TSchema>;
  label?: ReactNode;
};

const BoxIcon = styled(Box)(() => ({
  position: 'absolute',
  right: '20px',
  top: '50%',
  transform: 'translateY(-100%)',
  display: 'flex',
  cursor: 'pointer',
}));

const FormInput = <TSchema extends FieldValues>({
  name,
  label,
  type,
  control,
  ...props
}: FormInputProps<TSchema>) => {
  const isPassword = type === 'password';
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl component="fieldset" sx={{ width: '100%' }}>
      <StyledFormLabel htmlFor={name}>{label}</StyledFormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box sx={{ position: 'relative', width: '100%' }}>
              <StyledInput
                id={name}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                password={Number(isPassword)}
                type={showPassword ? 'text' : type}
                {...props}
              />
              <Typography
                variant={'error'}
                sx={{ opacity: !!error?.message ? 1 : 0 }}
              >
                {error?.message || 'helper text'}
              </Typography>
              {isPassword && (
                <BoxIcon onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? <EyeIcon /> : <EyeIcon />}
                </BoxIcon>
              )}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormInput;
