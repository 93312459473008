import { Controller, Path, useFormContext } from 'react-hook-form';
import { JobCategoryFormInputs } from '@/pages/JobPostingPage';
import SelectableList from '@/components/common/SelectableList';
import CollapsibleBlock from '@/components/common/CollapsibleBlock';

type Props = {
  name: Path<JobCategoryFormInputs>;
  subcategories: {
    id: string;
    name: string;
  }[];
  isSingleSelect?: boolean;
};

const JobPostingSubcategoriesBlock = ({
  subcategories,
  isSingleSelect = false,
  name,
}: Props) => {
  const { control, setValue, watch } = useFormContext<JobCategoryFormInputs>();
  const selectedSubcategoryId = watch('selectedSubcategoryId') || '';

  const selectedCategory = subcategories.find(
    (f) => f.id === selectedSubcategoryId
  );

  return (
    <CollapsibleBlock title={'Subcategories'}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <SelectableList
              items={subcategories}
              selectedValues={selectedCategory ? [selectedCategory] : []}
              isSingleSelect={isSingleSelect}
              onChange={(updatedValues) => {
                field.onChange(updatedValues?.[0]?.id);
                setValue('filters', []);
              }}
            />
          </>
        )}
      />
    </CollapsibleBlock>
  );
};

export default JobPostingSubcategoriesBlock;
