import Layout from '@/components/common/Layout';
import PageBackComponent from '@/components/common/PageBackComponent';
import JobPostingStepper from '@/pages/JobPostingPage/components/JobPostingStepper.tsx';
import JobPostingCategories from '@/pages/JobPostingPage/components/JobPostingCategories.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import JobPostingSecondStep from '@/pages/JobPostingPage/components/JobPostingSecondStep.tsx';
import { useJobPostingStepsStore } from '@/pages/JobPostingPage/store/jobPostingStepStore.ts';
import { useNavigate } from 'react-router-dom';
import {
  useCategoriesQuery,
  useSubcategoriesQuery,
} from '@/api/jobPosting/queries.ts';
import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Modal from '@/components/common/Modal';
import ConfirmIcon from '@/assets/icons/modal_confirm_icon.svg?react';

const SubcategoryFiltersType = z.object({
  id: z.string(),
  filterValues: z.array(
    z.object({
      id: z.string(),
      value: z.string(),
    })
  ),
});

const jobCategorySchema = z.object({
  selectedCategoryId: z.string().min(1, 'You must select a category'),
  selectedSubcategoryId: z.string().min(1, 'You must select a category'),
  filters: z.array(SubcategoryFiltersType).optional(),
});

export type JobCategoryFormInputs = z.infer<typeof jobCategorySchema>;

const JobPostingPage = () => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleClose = () => {
    setOpenConfirmation(false);
  };
  const navigate = useNavigate();
  const currentStep = useJobPostingStepsStore((state) => state.currentStep);
  const setPrevNextStep = useJobPostingStepsStore(
    (state) => state.setPrevNextStep
  );
  const methods = useForm<JobCategoryFormInputs>({
    resolver: zodResolver(jobCategorySchema),
    defaultValues: {
      selectedCategoryId: '',
      selectedSubcategoryId: '',
    },
  });

  const { watch, handleSubmit } = methods;

  const onSubmit = () => {
    setOpenConfirmation(true);
  };

  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else {
      setPrevNextStep(-1);
    }
  };

  const selectedCategoryId = watch('selectedCategoryId');

  const { data: categories } = useCategoriesQuery();
  const { data: subcategories } = useSubcategoriesQuery(selectedCategoryId);

  return (
    <Layout>
      <PageBackComponent onBackButton={handleBackButton} title={'Post A Job'} />
      <JobPostingStepper />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit, () => {
            setOpenConfirmation(true);
          })}
        >
          {currentStep === 1 && (
            <JobPostingCategories categories={categories || []} />
          )}
          {currentStep === 2 && (
            <JobPostingSecondStep subcategories={subcategories || []} />
          )}
        </form>
      </FormProvider>
      <Modal open={openConfirmation} onClose={handleClose}>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <ConfirmIcon />
          </Box>
          <Typography
            sx={{ textAlign: 'center', marginTop: '20px' }}
            variant={'poppins24Medium'}
          >
            Job Successfully Posted
          </Typography>
          <Typography
            sx={{ textAlign: 'center', marginTop: '20px' }}
            variant={'poppins16Regular'}
          >
            Congratulations! You have successfully posted your job on TALENVER.
          </Typography>
          <Typography
            sx={{ textAlign: 'center', marginTop: '20px' }}
            variant={'poppins16Regular'}
          >
            Your job in now active on TALENVER.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{ maxWidth: '200px', width: '100%', marginTop: '40px' }}
              onClick={handleClose}
              variant={'contained'}
            >
              Okay
            </Button>
          </Box>
        </>
      </Modal>
    </Layout>
  );
};

export default JobPostingPage;
