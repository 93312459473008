export enum PAGE_ROUTES {
  Registration = 'signup',
  EmailVerification = 'verify-email',
  Onboarding = 'onboarding',
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  Landing = '',
  JobPosting = 'job-posting',
}

export const AVAILABILITY_SELECT_OPTIONS = [
  { value: 'VIDEO', label: 'Video Call' },
  { value: 'AUDIO', label: 'Audio Call' },
  { value: 'NONE', label: 'None' },
];

export const COMPANY_SIZE_MARKS = [
  { value: 0, label: '0-50' },
  { value: 1, label: '50-100' },
  { value: 2, label: '100-500' },
  { value: 3, label: '500-1000' },
  { value: 4, label: '1000+' },
];
