import { ProtectedRoute } from '@/components/common/ProtectedRoutes/ProtectedRoutes.tsx';
import { ReactNode } from 'react';
import { useAuthStore } from '@/store/authStore.ts';

interface Props {
  path: string;
  children: ReactNode;
  isProtected?: boolean;
  accessCheck?: (
    isAuth: boolean,
    isEmailVerified: boolean,
    isProfileCompleted: boolean
  ) => boolean;
  redirectTo?: string;
}

export const ProtectedRouteWrapper = ({
  children,
  isProtected,
  redirectTo = 'signup',
  accessCheck,
}: Props) => {
  const { isAuth, isEmailVerified, isProfileCompleted } = useAuthStore();

  const isAllowed =
    !isProtected ||
    (accessCheck && accessCheck(isAuth, isEmailVerified, isProfileCompleted));

  return (
    <ProtectedRoute isAllowed={!!isAllowed} redirectTo={redirectTo}>
      {children}
    </ProtectedRoute>
  );
};
