import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    poppins10Regular: React.CSSProperties;
    poppins13Bold: React.CSSProperties;
    poppins14Bold: React.CSSProperties;
    poppins14Regular: React.CSSProperties;
    poppins16Regular: React.CSSProperties;
    poppins16Bold: React.CSSProperties;
    poppins16Medium: React.CSSProperties;
    poppins18Bold: React.CSSProperties;
    poppins18Regular: React.CSSProperties;
    poppins20Regular: React.CSSProperties;
    poppins20Semibold: React.CSSProperties;
    poppins24Medium: React.CSSProperties;
    poppins24Bold: React.CSSProperties;
    poppins24Regular: React.CSSProperties;
    poppins30Semibold: React.CSSProperties;
    poppins30Regular: React.CSSProperties;
    poppins65Regular: React.CSSProperties;
    inter60Bold: React.CSSProperties;
    inter60Lighter: React.CSSProperties;
    inter30Regular: React.CSSProperties;
    error: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    poppins10Regular?: React.CSSProperties;
    poppins13Bold?: React.CSSProperties;
    poppins14Bold?: React.CSSProperties;
    poppins14Regular?: React.CSSProperties;
    poppins16Regular?: React.CSSProperties;
    poppins16Bold?: React.CSSProperties;
    poppins16Medium?: React.CSSProperties;
    poppins18Bold?: React.CSSProperties;
    poppins18Regular?: React.CSSProperties;
    poppins20Regular?: React.CSSProperties;
    poppins20Semibold?: React.CSSProperties;
    poppins24Medium?: React.CSSProperties;
    poppins24Bold?: React.CSSProperties;
    poppins24Regular?: React.CSSProperties;
    poppins30Semibold?: React.CSSProperties;
    poppins30Regular?: React.CSSProperties;
    poppins65Regular?: React.CSSProperties;
    inter60Bold?: React.CSSProperties;
    inter60Lighter?: React.CSSProperties;
    inter30Regular?: React.CSSProperties;
    error?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poppins10Regular: true;
    poppins13Bold: true;
    poppins14Bold: true;
    poppins14Regular: true;
    poppins16Regular: true;
    poppins16Bold: true;
    poppins16Medium: true;
    poppins18Bold: true;
    poppins18Regular: true;
    poppins20Regular: true;
    poppins20Semibold: true;
    poppins24Medium: true;
    poppins24Bold: true;
    poppins24Regular: true;
    poppins30Semibold: true;
    poppins30Regular: true;
    poppins65Regular: true;
    inter60Bold: true;
    inter60Lighter: true;
    inter30Regular: true;
    error: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    blue1: string;
    blue2: string;
    blue3: string;
    blue4: string;
    gray1: string;
    gray2: string;
    gray3: string;
    gray4: string;
    gray5: string;
    gray6: string;
    gray7: string;
    gray8: string;
    purple1: string;
    purple2: string;
    purple3: string;
    purple4: string;
    purple5: string;
    gradient: string;
    gradient11: string;
    gradient1: string;
    gradient3: string;
    gradient7: string;
    red1: string;
  }

  interface PaletteOptions {
    blue1?: string;
    blue2?: string;
    blue3?: string;
    blue4?: string;
    gray1?: string;
    gray2?: string;
    gray3?: string;
    gray4?: string;
    gray5?: string;
    gray6?: string;
    gray7?: string;
    gray8?: string;
    purple1?: string;
    purple2?: string;
    purple3?: string;
    purple4?: string;
    purple5?: string;
    gradient: string;
    gradient11: string;
    gradient1: string;
    gradient3: string;
    gradient7: string;
    red1: string;
  }
}

// const baseTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: { main: '#4D63F5' },
    blue1: '#DAE6F4',
    blue2: '#36A0FF',
    blue3: '#2F4EF8',
    blue4: '#3D74F6',
    gray1: '#F3F8FD',
    gray2: '#EEF5F5',
    gray3: '#EBEBF4',
    gray4: '#D9D9D9',
    gray5: '#F3F8FD',
    gray6: '#EAEFF0',
    gray7: '#EAEFF0',
    gray8: '#EBECF3',
    purple1: '#4D63F5',
    purple2: '#6A7EFF',
    purple3: '#606ECA',
    purple4: '#9B8AFF',
    purple5: '#BEC6FF',
    red1: '#f44336',
    gradient:
      'linear-gradient(160deg, rgba(252, 241, 251, 1) 0%, rgba(215, 229, 255, 0.73) 33%, rgba(250, 234, 219, 1) 66%, rgba(208, 214, 255, 1) 100%)',
    gradient11: 'linear-gradient(180deg, #394FDE 0%, #86A1F1 100%)',
    gradient1:
      'linear-gradient(160deg, #465BE7 0%, #4D63F5 33%, #DFF1F8 66%, #F6EBE1 100%)',
    gradient3:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.5), hsla(0, 0%, 100%, 0.5))',
    gradient7:
      'linear-gradient(160deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 100%)',
  },
  typography: {
    fontFamily: ['Poppins', 'Inter', 'sans-serif'].join(','),
    allVariants: {
      color: '#051320', // Primary color for typography
    },
    poppins10Regular: {
      fontSize: '10px',
      fontWeight: 400,
    },
    poppins13Bold: {
      fontSize: '13px',
      fontWeight: 700,
    },
    poppins14Bold: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '120%',
    },
    poppins14Regular: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%',
    },
    poppins16Regular: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '120%',
    },
    poppins16Medium: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '140%',
    },
    poppins18Bold: {
      fontSize: '18px',
      fontWeight: 700,
    },
    poppins18Regular: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '140%',
    },
    poppins20Regular: {
      fontSize: '20px',
      fontWeight: 400,
    },
    poppins20Semibold: {
      fontSize: '20px',
      fontWeight: 600,
    },
    poppins24Regular: {
      fontSize: '24px',
      fontWeight: 400,
    },
    poppins24Medium: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '120%',
    },
    poppins24Bold: {
      fontSize: '24px',
      fontWeight: 700,
    },
    poppins30Semibold: {
      fontSize: '30px',
      fontWeight: 600,
      lineHeight: '120%',
    },
    poppins30Regular: {
      fontSize: '30px',
      fontWeight: 400,
      lineHeight: '120%',
    },
    poppins65Regular: {
      fontSize: '65px',
      fontWeight: 400,
      lineHeight: '130%',
    },
    inter60Bold: {
      fontSize: '60px',
      fontFamily: 'Inter',
      fontWeight: 700,
      color: '#fff',
      lineHeight: '70px',
    },
    inter60Lighter: {
      fontSize: '60px',
      fontFamily: 'Inter',
      fontWeight: 300,
      color: '#fff',
      lineHeight: '70px',
    },
    inter30Regular: {
      fontFamily: 'Inter',
      fontSize: '30px',
      fontWeight: 400,
    },
    error: {
      fontSize: '12px',
      color: '#f44336',
      paddingTop: '4px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {},
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            minHeight: '60px',
            fontSize: '18px',
            borderRadius: '12px',
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            minHeight: '60px',
            fontSize: '18px',
            borderRadius: '12px',
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            minHeight: '60px',
            fontSize: '18px',
            borderRadius: '12px',
            fontWeight: 600,
            textTransform: 'none',
            boxShadow: 'none',
          },
        },
      ],
    },
  },
});

export default theme;
