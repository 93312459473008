import { apiRequest } from '@/libs/apiRequest.ts';
import { CategoryType } from '@/api/jobPosting/types.ts';
import { SubcategoryType } from '@/types/filters.ts';

export const fetchCategories = async () => {
  const response = await apiRequest<CategoryType[]>('/categories', {
    method: 'GET',
    withAuth: true,
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};

export const fetchSubcategories = async (categoryId: string) => {
  const response = await apiRequest<SubcategoryType[]>('/subcategories', {
    method: 'GET',
    withAuth: true,
    params: { category_id: categoryId },
  });

  if (response.error) {
    throw new Error(response.error);
  }

  return response.data;
};
