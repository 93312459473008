import { useQuery } from '@tanstack/react-query';
import {
  fetchCategories,
  fetchSubcategories,
} from '@/api/jobPosting/fetchers.ts';

export const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: () => fetchCategories(),
    placeholderData: [],
  });
};
export const useSubcategoriesQuery = (categoryId: string) => {
  return useQuery({
    queryKey: ['subcategories', categoryId],
    queryFn: () => fetchSubcategories(categoryId),
    placeholderData: [],
    enabled: !!categoryId,
  });
};
