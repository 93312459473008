import { Box, Button } from '@mui/material';
import JobPostingFilterBlock from '@/pages/JobPostingPage/components/JobPostingFilterBlock.tsx';
import JobPostingSubcategoriesBlock from '@/pages/JobPostingPage/components/JobPostingSubcategoriesBlock.tsx';
import { useFormContext } from 'react-hook-form';
import { JobCategoryFormInputs } from '@/pages/JobPostingPage';
import { SubcategoryType } from '@/types/filters.ts';

const JobPostingSecondStep = ({
  subcategories,
}: {
  subcategories: SubcategoryType[];
}) => {
  const { watch } = useFormContext<JobCategoryFormInputs>();
  const selectedSubcategoryId = watch('selectedSubcategoryId');
  const selectedCategory = subcategories?.find(
    (el) => el.id === selectedSubcategoryId
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        marginTop: '40px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <JobPostingSubcategoriesBlock
        name={'selectedSubcategoryId'}
        subcategories={subcategories}
        isSingleSelect={true}
      />
      {selectedCategory?.filters?.map((filter) => {
        return (
          <JobPostingFilterBlock
            key={filter?.id}
            name={'filters'}
            filter={filter}
          />
        );
      })}
      <Button
        variant={'contained'}
        sx={{ width: '100%', maxWidth: '460px' }}
        type={'submit'}
      >
        Post a job
      </Button>
    </Box>
  );
};

export default JobPostingSecondStep;
